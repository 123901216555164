<template>
  <div class="">
    <div class="layout column">
      <div class="card-title-text v-card__title display-2">
        {{ title }}
        <div class="accept-card-box">
          <img src="@/assets/secure-stripe-payment-logo.png" alt="">
        </div>
      </div>
    </div>
    <v-form
      ref="form"
      v-model="valid"
      medium
    >
      <v-text-field
        v-model="payment.name"
        outlined
        name="name"
        :rules="cardHolderNameRules"
        :label="'Name on Card*'"
        required
      />
      <v-text-field
        v-model="payment.number"
        v-cardformat:formatCardNumber
        outlined
        name="card-number"
        :rules="numberRules"
        :label="'Card Number*'"
        required
        inputmode="numeric"
      />

      <v-row no-gutters>
        <v-col :md="6" :sm="6">
          <v-text-field
            v-model="payment.expired_date"
            v-cardformat:formatCardExpiry
            outlined
            name="expired_date"
            :rules="expiredDateRules"
            :label="'MM / YY*'"
            required
            inputmode="numeric"
          />
        </v-col>
        <v-col :md="6" :sm="6">
          <div style="padding-left:5px">
            <v-text-field
              v-model="payment.cvc"
              v-cardformat:formatCardCVC
              outlined
              name="cvc"
              :rules="cvcRules"
              :label="'CVC*'"
              inputmode="numeric"
              required
            />
          </div>

        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :md="6" :sm="6" :xs="6">
          <v-text-field
            v-model="payment.address_zip"
            v-cardformat:restrictNumeric
            outlined
            name="address_zip"
            :rules="zipCodeRules"
            :label="'Zip Code*'"
            required
            inputmode="numeric"
          />
        </v-col>
        <v-col :md="6" :sm="6" :xs="6" />
      </v-row>
    </v-form>
  </div>
</template>

<script>

import logo from '@/assets/logo.png'

export default {
  name: 'AddPayment',
  props: {
    title: {
      type: String,
      default: 'Set up your credit or debit card'
    }
  },
  data: () => ({
    activePicker: null,
    valid: true,
    loading: false,
    logo: logo,
    menu: false,
    payment: {
      address_zip: '',
      name: '',
      number: '',
      expired_date: '',
      cvc: null,
      exp_year: null,
      exp_month: null
    },
    zipCodeRules: [
      v => {
        if (!v) {
          return 'Zip Code is required'
        }
        if (v.trim().length > 20 || v.trim().length < 2) {
          return 'Zip Code must be valid'
        }
        return true
      }
    ],
    cardHolderNameRules: [
      v => {
        if (!v) {
          return 'Name is required'
        }
        if (v.trim().length > 32 || v.trim().length < 2) {
          return 'Name must be valid'
        }
        return true
      }
    ],
    numberRules: [
      v => !!v || 'Card Number is required'
    ],
    expiredDateRules: [
      v => !!v || 'Expired Date is required'
    ],
    cvcRules: [
      v => !!v || 'CVC is required'

    ]
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  created() {

  },
  methods: {
    validate() {
      this.$refs.form.validate()
      return this.valid
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    getPaymentData() {
      if (!this.validate()) {
        return
      }
      const payment = this.payment
      var exp_month = payment.expired_date.replaceAll(' ', '').split('/')[0]
      var exp_year = payment.expired_date.replaceAll(' ', '').split('/')[1]
      var data = {
        first_name: payment.name,
        last_name: payment.name,
        number: payment.number.replaceAll(' ', ''),
        cvc: payment.cvc,
        exp_year: exp_year,
        exp_month: exp_month,
        name: payment.name,
        address_zip: payment.address_zip
      }
      return data
    }
  }
}
</script>
<style scoped lang="scss">

.card-title-text{
  word-break: break-word;
  text-align: left;
  color: #E040FB;
}

.accept-card-box{
    img{
      width: 200px;
    }
  }

</style>
